import { useState } from "react";
// import OrderFormModal from "./ui/OrderFormModal";
import { useUserId } from "../api/user";

const Footer = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    data: userId,
    isLoading: userIdIsLoading,
    isError: userIdIsError,
  } = useUserId();

  return (
    <footer
      className='footer bg-light p-2 '
      style={{ borderTop: "0.1rem solid #e5e5e5" }}
    >
      <div className='container-fluid'>
        <div className='d-flex flex-column flex-md-row justify-content-center align-items-center'>
          <div className='fs-6'>
            <a
              href='https://www.linkedin.com/company/spectra-plasmonics/'
              target='_blank'
            >
              <i className='bi bi-linkedin mx-1 text-muted'></i>
            </a>
            <a href='https://x.com/@SPlasmonics' target='_blank'>
              <i className='bi bi-twitter-x mx-1 text-muted'></i>
            </a>
            <a href='mailto:info@spectraplasmonics.com' target='_blank'>
              <i className='bi bi-envelope mx-1 text-muted'></i>
            </a>
            <a href='https://www.spectraplasmonics.com/' target='_blank'>
              <i className='bi bi-globe2 mx-1 text-muted'></i>
            </a>
          </div>
          {/* <div className='d-flex flex-column flex-md-row justify-content-center align-items-center'>
          
          <div className='small text-start text-muted mb-2 mb-md-0'>
            Need any help?{" "}
            <a
              href='https://www.spectraplasmonics.com/amplifi-id-faq'
              target='_blank'
            >
              Click here to access our FAQ
            </a>
          </div>
          {userId && (
            <div className='small text-muted text-start mb-2 mb-md-0 '>
              Do you need more test kits?{" "}
              <a
                href='https://www.spectraplasmonics.com/amplifi-id-faq'
                target='_blank'
                onClick={(event) => {
                  event.preventDefault();
                  setModalVisible(true);
                }}
              >
                Click here to order test kits
              </a>
            </div>
          )} */}

          <div className='small text-muted ms-md-auto text-center text-md-end'>
            Copyright © - 2024 Spectra Plasmonics Inc.
          </div>
        </div>
      </div>
      {/* <OrderFormModal
        showModal={modalVisible}
        handleClose={() => setModalVisible(false)}
      ></OrderFormModal> */}
    </footer>
  );
};
export default Footer;
