import React, { useState, useEffect } from "react";
import "../styles/Body.css";
import { CardA, ScoreCard } from "./ui/Cards";
import TrendingDrugs from "./TrendingDrugs";
// import NumberVsCard from "./stats/NumberVsCard";
import AdverseReactionPlot from "./AdverseReactionPlot";
import { useCommonDrugs, useScores, useAdverseReactionsPlotData } from "../api";
import { useUserSettings, useUserLocations } from "../api/user";
import { se } from "date-fns/locale";
import "../styles/styles.css"; // Import the CSS file

const Body = () => {
  // Initialize state variables
  const [now] = useState(new Date().setHours(23, 59, 59, 999));
  const [dateRange, setDateRange] = useState(7);
  const [pastStart, setPastStart] = useState(
    new Date(now - dateRange * 24 * 60 * 60 * 1000)
  );
  const [location, setLocation] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [drug, setDrug] = useState("All");

  // Get user settings and locations
  const { data: settingsData, isFetching: isFetchingUserSettings } =
    useUserSettings();
  const { data: locationsData, isFetching: isFetchingUserLocations } =
    useUserLocations();

  // Set location name based on current location ID
  useEffect(() => {
    // if (locationsData && settingsData) {
    //   setLocation(
    //     locationsData.filter(
    //       (loc) => loc.locationId === settingsData.currentLoc
    //     )[0].name
    //   );
    // }

    if (locationsData && locationsData.length > 1) {
      setSelectedLocation("All");
    } else if (locationsData && locationsData.length === 1) {
      setSelectedLocation(locationsData[0].locationId);
    }
  }, [locationsData]);

  // Get common drugs and adverse reactions
  const {
    data: commonDrugs,
    isFetching: isFetchingCommonDrugs,
    isError: isErrorCommonDrugs,
  } = useCommonDrugs(pastStart, drug, selectedLocation);

  const { data: scoresData, isFetching: isFetchingScores } = useScores(
    pastStart,
    now,
    new Date(pastStart.getTime() - dateRange * 24 * 60 * 60 * 1000),
    pastStart,
    selectedLocation
  );

  const {
    data: adverseReactionsPlotData,
    isFetching: isFetchingAdverseReactionsPlotData,
  } = useAdverseReactionsPlotData(pastStart, selectedLocation);

  const handleDateRangeChange = (event) => {
    const selectedDateRange = parseInt(event.target.value, 10);
    setDateRange(selectedDateRange);

    const newPastStart = new Date(
      now - selectedDateRange * 24 * 60 * 60 * 1000
    );
    setPastStart(newPastStart);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  return (
    <div
      className='px-sm-3 px-lg-5 py-3 full-height'
      style={{ marginTop: "79px" }}
    >
      <div className='shadow card full-height'>
        <div className='card-body full-height d-flex flex-column justify-content-around'>
          <div className='d-flex justify-content-between flex-wrap'>
            <h5 className='card-title'> Testing Insights</h5>
            <div className='d-flex flex-wrap' style={{ marginBottom: "1rem" }}>
              <select
                className='form-select w-auto'
                id='dateRangeSelect'
                value={dateRange}
                onChange={handleDateRangeChange}
              >
                <option value='7'>Past 7 days</option>
                <option value='14'>Past 14 days</option>
                <option value='30'>Past 30 days</option>
                <option value='60'>Past 60 days</option>
                <option value='90'>Past 90 days</option>
              </select>

              {!isFetchingUserLocations && locationsData.length > 1 && (
                <select
                  className='form-select w-auto ms-2'
                  id='locationSelect'
                  value={selectedLocation}
                  onChange={handleLocationChange}
                >
                  <option key={"All"} value={"All"}>
                    All Locations
                  </option>
                  {locationsData.map((location) => (
                    <option
                      key={location.locationId}
                      value={location.locationId}
                    >
                      {location.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>

          <div className='d-flex flex-column justify-content-evenly full-height '>
            <div className='row justify-content-evenly d-flex '>
              <div className='col-lg-4 col-sm-12 pe-lg-2 py-1 py-lg-0 scorecard-container'>
                <ScoreCard
                  title='Samples Tested'
                  help={`Total number of samples in the past ${dateRange} days`}
                  isFetchingScores={isFetchingScores}
                  value={scoresData?.results.currentSamples}
                  vsValue={scoresData?.results.prevSamples}
                />
              </div>
              <div className='col-lg-4 col-sm-12 px-lg-2 py-1 py-lg-0 scorecard-container'>
                <ScoreCard
                  title='Presumption Score'
                  help={`Percentage of samples guessed correctly in the past ${dateRange} days`}
                  isFetchingScores={isFetchingScores}
                  unit='%'
                  value={scoresData?.results.currentPresScore.toFixed(2)}
                  vsValue={scoresData?.results.prevPresScore.toFixed(2)}
                />
              </div>
              <div className='col-lg-4 col-sm-12 ps-lg-2 pt-1 py-lg-0 scorecard-container'>
                <ScoreCard
                  title='Adverse Reaction Score'
                  help={`Number and percentage of samples with adverse reactions in the past ${dateRange} days`}
                  isFetchingScores={isFetchingScores}
                  unit='%'
                  value={scoresData?.results.currentAdverseScore.toFixed(2)}
                  vsValue={scoresData?.results.prevAdverseScore.toFixed(2)}
                />
              </div>
            </div>
            <div
              className='row justify-content-evenly d-flex align-items-stretch flex-wrap'
              style={{ flexGrow: "2" }}
            >
              {/* Trending drugs */}
              <div className='col-12 col-xl-6 mt-2 plot-card-container full-height'>
                <CardA
                  icon='fas fa-chart-pie'
                  title='Top 7 Findings'
                  className='flex-fill'
                >
                  {isFetchingCommonDrugs ||
                  isFetchingScores ||
                  isFetchingAdverseReactionsPlotData ? (
                    <div className='spinner-border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  ) : isErrorCommonDrugs ? (
                    <span>Error. Please try reloading the page.</span>
                  ) : (
                    <TrendingDrugs commonDrugs={commonDrugs.slice(0, 10)} />
                  )}
                </CardA>
              </div>

              <div className='col-12 col-xl-6 mt-2 plot-card-container full-height '>
                <CardA
                  icon='fas fa-chart-line'
                  title='Adverse Reactions'
                  className='flex-fill'
                >
                  {isFetchingAdverseReactionsPlotData ||
                  isFetchingScores ||
                  isFetchingAdverseReactionsPlotData ? (
                    <div className='spinner-border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  ) : adverseReactionsPlotData.length === 0 ? (
                    <div
                      className='d-flex justify-content-center align-items-center h-100 bg-light'
                      style={{ minWidth: "90%" }}
                    >
                      No data
                    </div>
                  ) : (
                    <AdverseReactionPlot
                      period={Number(dateRange)}
                      adverseData={adverseReactionsPlotData.find(
                        (item) => item._id === true
                      )}
                      noAdverseData={adverseReactionsPlotData.find(
                        (item) => item._id === false
                      )}
                    />
                  )}
                </CardA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
