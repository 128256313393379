import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderFormModal from "./ui/OrderFormModal";
import { userActions } from "../actions/auth";
import { history } from "../helpers/history";

// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useUserSettings, useUserId } from "../api/user";
import spectraLogo from "../static/img/Spectra Plasmonics logo.webp";
//Elements and CSS from AntDesign
import "../styles/Navbar.css";
import { Link } from "react-router-dom";
// import Login from './Login'
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import DropdownMenu from "./navbar/DropdownMenu";
import MoreDropdown from "./navbar/MoreDropdown";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const SpectraNavbar = ({ authenticated }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const [modalVisible, setModalVisible] = useState(false);
  const { data: settings } = useUserSettings();
  const {
    data: userId,
    isLoading: userIdIsLoading,
    isError: userIdIsError,
  } = useUserId();

  useEffect(() => {
    // Check browser storage for authentication status
    const storedAuthStatus = localStorage.getItem("isAuthenticated");
    if (storedAuthStatus === "true") {
      // If authenticated, dispatch appropriate action
      dispatch(userActions.loginSuccess());
    }
  }, [dispatch]);

  return (
    <Navbar
      id='mainNav'
      fixed='top'
      className=' bg-light shadow '
      style={{ borderBottom: "0.3rem solid #e3803b" }}
      expand='lg'
    >
      <Container fluid className='d-flex flex-no-wrap align-items-center'>
        <Navbar.Brand href='/'>
          <img
            alt='Spectra Plasmonics Logo'
            src={spectraLogo}
            style={{ maxWidth: "20rem", width: "50vw", minWidth: "14rem" }}
            className='d-inline-block align-top  '
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='navbarResponsive' />
        <Navbar.Collapse className='justify-content-end' id='navbarResponsive'>
          {userIdIsLoading && (
            <div className='spinner-border' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          )}
          {userIdIsError && (
            <Nav className=' ms-auto'>
              <Nav.Link to='/login'>Log in</Nav.Link>
            </Nav>
          )}

          {userId && (
            <Nav className='ms-auto align-items-center'>
              <DropdownMenu />

              <Nav.Link href='/'>Testing Insights</Nav.Link>

              <Nav.Link href='/recent'>Recent Samples</Nav.Link>

              <MoreDropdown setModalVisible={setModalVisible} />

              <Nav.Item
                className={"btn btn-primary btn-md my-auto ms-2"}
                onClick={() => history.push("/new")} //forces page reload
              >
                New Sample
              </Nav.Item>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
      <OrderFormModal
        showModal={modalVisible}
        handleClose={() => setModalVisible(false)}
      ></OrderFormModal>
    </Navbar>
  );
};

export default SpectraNavbar;
