import React from "react";

export function ColourCircle({ id, colour, selected, updateColour, text }) {
  let style;
  let icon;

  if (colour === "#AARRGGBB") {
    style = {
      width: "5rem",
      height: "5rem",
      backgroundImage:
        "linear-gradient(45deg, #ffffff 25%, #bcc0c2 25%, #bcc0c2 50%, #ffffff 50%, #ffffff 75%, #bcc0c2 75%, #bcc0c2 100%)",
      backgroundSize: "14.14px 14.14px",
    };
  } else {
    style = {
      width: "5rem",
      height: "5rem",
      backgroundColor: colour,
    };
  }

  if (colour === "#000000") {
    icon = "bi bi-check-circle text-secondary";
  } else {
    icon = "bi bi-check-circle";
  }

  return (
    <div>
      <button
        className={
          selected
            ? "row align-items-center justify-content-center rounded mx-4 my-2"
            : "row align-items-center justify-content-center rounded mx-4 my-2 shadow"
        }
        style={style}
        onClick={() => updateColour(colour, id, text)}
      >
        {selected && (
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className={icon}
              viewBox='0 0 16 16'
            >
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
              <path d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z' />
            </svg>
          </div>
        )}
      </button>
      <span className='row justify-content-center'>{text}</span>
    </div>
  );
}

export function ColourCircleB({ colour, label }) {
  if (label === "Clear") {
    colour =
      "repeating-linear-gradient(45deg,#ffffff,#ffffff 3px,#dbdbdb 3px,#dbdbdb 6px)";
  }
  return (
    <div className='d-flex align-items-center'>
      <div
        className='shadow'
        style={{
          background: colour,
          borderColor: "lightgrey",
          borderStyle: "solid",
          borderWidth: "0.01rem",
          borderRadius: "10rem",
          width: "1.5rem",
          height: "1.5rem",
        }}
      ></div>
      <span className='ms-2'>{label}</span>
    </div>
  );
}

export function ColourCircleC({ data }) {
  if (!data || data.error) {
    return (
      <div className='d-flex'>
        <div
          className='shadow'
          style={{
            background: "white",
            borderColor: "lightgrey",
            borderStyle: "solid",
            borderWidth: "0.01rem",
            borderRadius: "10rem",
            width: "1.5rem",
            height: "1.5rem",
          }}
        ></div>
        <span className='ms-2 my-auto'>Error ...</span>
      </div>
    );
  }

  let colour = data.hexCode;

  if (data.label === "Clear") {
    colour =
      "repeating-linear-gradient(45deg,#ffffff,#ffffff 3px,#dbdbdb 3px,#dbdbdb 6px)";
  }
  return (
    <div className='d-flex'>
      <div
        className='shadow'
        style={{
          background: colour,
          borderColor: "lightgrey",
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: "25px",
          width: "25px",
          height: "25px",
          minWidth: "25px",
        }}
      ></div>
      <span className='ms-2 my-auto'>
        {data?.label === "Light Grey"
          ? data?.label
          : data?.id?.includes("02")
          ? `Light ${data.label}` //adding "light" in front of colour label for light colours
          : data?.label}
      </span>
    </div>
  );
}

// Blue
// Teal
// Green
// White
// Clear

export function ColourSquare({
  id,
  colour,
  selected,
  updateColour,
  text,
  showLabel,
}) {
  let style;
  let icon;

  if (colour === "#AARRGGBB") {
    style = {
      width: "2.5rem",
      height: "2.5rem",
      backgroundImage:
        "linear-gradient(45deg, #ffffff 25%, #bcc0c2 25%, #bcc0c2 50%, #ffffff 50%, #ffffff 75%, #bcc0c2 75%, #bcc0c2 100%)",
      backgroundSize: "14.14px 14.14px",
      margin: "3px",
    };
  } else if (colour === "#FFFFFF") {
    style = {
      width: "2.5rem",
      height: "2.5rem",
      backgroundColor: colour,
      borderColor: "black",
      margin: "3px",
    };
  } else {
    style = {
      width: "2.5rem",
      height: "2.5rem",
      backgroundColor: colour,
      borderColor: "white",
      margin: "3px",
    };
  }

  if (
    colour === "#000000" ||
    colour === "#515251" ||
    colour === "#5B3A1E" ||
    colour === "#0870D0" ||
    colour === "#8941BA"
  ) {
    icon = "bi bi-check-circle-fill text-white";
  } else {
    icon = "bi bi-check-circle-fill";
  }

  return (
    <div
      className='d-flex flex-column'
      style={showLabel && { margin: "auto 5px" }}
    >
      {showLabel && (
        <div className='flex-row font-weight-bold text-center'>{text}</div>
      )}
      <button
        className={
          selected
            ? "flex-row align-self-center rounded border-3 border-dark"
            : "flex-row align-self-center rounded shadow"
        }
        style={style}
        onClick={() => updateColour(colour, id, text)}
      >
        {selected && (
          <div className='d-flex justify-content-center '>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              fill='currentColor'
              className={icon}
              viewBox='0 0 16 16'
              style={{ overflow: "visible" }}
            >
              <path
                d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'
                stroke='currentColor'
                strokeWidth='1'
              />
              <path
                d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z'
                stroke='currentColor'
                strokeWidth='1'
              />
            </svg>
          </div>
        )}
      </button>
    </div>
  );
}
