import React from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/SpectraNavbar.js";

const Unauthorized = () => {
  return (
    <div>
      <Navbar />
      <div className='container mt-5'>
        <header className='py-5'>You do not have access to this page.</header>
      </div>
      <Footer />
    </div>
  );
};

export default Unauthorized;
