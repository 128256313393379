import React from "react"; // createContext // useMemo, // useContext, // useEffect, // useState,
// import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
//import Alert from "react-bootstrap/Alert";
import Footer from "../components/Footer";
import Navbar from "../components/SpectraNavbar";
import { userActions } from "../actions/auth";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { store } from "../helpers/Store";
// import axios from "axios"
import "../styles/Login.css";

// const AuthContext = React.createContext();

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      submitted: "",
      type: this.props.type,
      isFetching: this.props.isFetching,
      isAuthenticated: this.props.isAuthenticated,
      isVisible: false,
    };
  }
  onHandle = (e) => {
    e.preventDefault();
    let { email, password } = this.state;
    this.props.dispatch(userActions.login(email, password));
  };
  onKeyHandle = (e) => {
    if (e.key === "Enter") {
      this.onHandle(e);
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.setState({
        isAuthenticated: this.props.isAuthenticated,
        isFetching: this.props.isFetching,
        type: this.props.type,
        isVisible: this.props.isVisible,
      });
    }
  }
  handleShowPassword = () => {
    let isVisible = this.state.isVisible;
    let visible = isVisible ? false : true;
    this.setState({ isVisible: visible });
  };
  render() {
    let { type } = this.state;
    return (
      <div
        id='main-wrapper'
        className='d-flex flex-column container-fluid bg-light min-vh-100'
      >
        <Navbar />

        <div
          className='d-flex justify-content-center align-items-center container-fluid flex-grow-1 h-100'
          style={{ paddingTop: "79px" }}
        >
          <div id='login-form' className='col-lg-8 col-12  bg-white p-5 border'>
            <header className='mb-4'>
              <h3>Log in</h3>
            </header>
            <Form className='my-3'>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  onChange={(e) => this.setState({ email: e.target.value })}
                  type='email'
                  placeholder='Enter email'
                />
                <Form.Text className='text-muted'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label>Password</Form.Label>
                <div className='password-field'>
                  <Form.Control
                    type={this.state.isVisible ? "text" : "password"}
                    placeholder='Password'
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    onKeyDown={this.onKeyHandle}
                  />
                  <span
                    className='show-password-btn'
                    onClick={this.handleShowPassword}
                  >
                    <FontAwesomeIcon
                      className='eye-icon'
                      icon={
                        this.state.isVisible ? "fas fa-eye" : "fas fa-eye-slash"
                      }
                    />
                  </span>
                </div>
              </Form.Group>
              {type === "LOGIN_FAILURE" && (
                <div className='form-control mb-3 is-invalid'>
                  email and password do not match.
                </div>
              )}
              <div className='d-grid'>
                <Button
                  variant='primary'
                  className='mt-4'
                  type='submit'
                  onClick={this.onHandle}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

/* function LoginAlert() {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant="warning" onClose={() => setShow(false)} dismissible>
        You must be logged in to view this page.
      </Alert>
    );
  }
  return <Button onClick={() => setShow(true)}>Show Alert</Button>;
} */

function mapStateToProps(state) {
  const { type, isAuthenticated, isFetching } = state.authentication;
  return { type, isAuthenticated, isFetching };
}
export default connect(mapStateToProps)(Login);
