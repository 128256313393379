import React, { useEffect } from "react";

//contexts
import { useSampleContext } from "./../../contexts";

import { useConnectorDevices, useServerDevices } from "../../api";
import styleDate from "../../util/dateStyler";

//components
import { CardA, CardD } from "./../ui/Cards";
import "antd/dist/antd.css";

import SampleCons from "../../constants/sample";

const WizardDeviceSelection = ({
  step,
  steps,
  isCalibrate,
  changeStep,
  pageID,
}) => {
  const [warning, setWarning] = React.useState(<></>);
  const [devices, setDevices] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [errMsg, setErrMsg] = React.useState("No devices found.");
  const { setCalibrationDevice } = useSampleContext();
  const connectorDevices = useConnectorDevices(
    reloadServerDevices,
    errorLogger
  );
  const serverDevices = useServerDevices(
    connectorDevices.data,
    setDevicesParser,
    errorLogger
  );

  /**
   * Every time the step is updated, check if this page was loaded
   * and refresh the devices if needed.
   * Note: this call isn't immediately after the step is updated (there is a
   * slight delay). As such, the previous device cards will appear for a
   * fraction of a second before the reload occurs if the page is switched to.
   * As such, devices are set to 'undefined' when the user changes pages to
   * prevent this.
   **/

  useEffect(() => {
    if (step === pageID && !isCalibrate) {
      reloadConnectorDevices();
    }
  }, [isCalibrate, step, pageID]);

  const handleDeviceChange = (device) => {
    console.log(device);
  };

  function errorLogger(err) {
    console.error(err);
    setDevices([]);
    setLoading(false); // deactivate loading spinner
  }

  function reloadConnectorDevices() {
    setLoading(true); // activate loading spinner
    connectorDevices.refetch();
  }

  function reloadServerDevices(response) {
    if (
      response &&
      response.status &&
      response.status >= 200 &&
      response.status < 300
    ) {
      serverDevices.refetch();
    } else {
      console.error("Could not retrieve devices from connector app.");
      setDevices([]);
      if (response && response.message) {
        setErrMsg(response.message);
      } else {
        setErrMsg("No devices found.");
      }
      setLoading(false); // deactivate loading spinner
    }
  }

  function setDevicesParser(serverDevices) {
    if (serverDevices && serverDevices.length > 0) {
      // Sort the devices by last calibrated date
      serverDevices.sort((a, b) => {
        return b.lastCalibration - a.lastCalibration;
      });

      setDevices(serverDevices);
      setLoading(false); // deactivate loading spinner
    } else if (!connectorDevices.isLoading && !serverDevices.isLoading) {
      setDevices([]);
      setLoading(false); // deactivate loading spinner
      setErrMsg("No devices found");
    }
  }

  if ((step !== pageID && pageID != "return") || isCalibrate) {
    return null;
  }

  return (
    <CardD
      title='Select a Device'
      subtitle='Click the button that corresponds to your device'
      spacing='col-6'
      buttonTitle='Refresh'
      buttonFunction={reloadConnectorDevices}
      buttonStyle='btn-outline-primary'
    >
      {warning}
      <div className='d-flex flex-column flex-grow-1 gap-3 justify-content-center'>
        {
          // While the devices are being fetched, show a loading spinner
          loading && (
            <div className='mx-auto'>
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          )
        }
        {
          // Check that the second fetch (to the server) has finished
          // Return the JSX for device cards, where each device in the array gets a card (if they exist)
          // Also modify the cards if the calibration date has expired
          !loading &&
            devices &&
            devices.length > 0 &&
            devices.map((d) => (
              <div className='shadow card' key={d.deviceId}>
                <div className='card-body'>
                  <div className='d-flex flex-row align-items-center justify-content-center flex-wrap'>
                    <div className=' d-flex justify-content-center'>
                      <img
                        src={require("../../images/AmplifiID.png")}
                        width={207}
                        height={140}
                      ></img>
                    </div>

                    <div className='flex-grow-1'>
                      <h5 className='card-title w-75'>{d.name}</h5>
                      <h6 className='card-subtitle mb-4 text-muted'>
                        Last calibrated:{" "}
                        {d.lastCalibration
                          ? styleDate(d.lastCalibration)
                          : "Never"}
                      </h6>
                    </div>
                    {d.lastCalibration >=
                    new Date(Date.now() - 3600000 * 24) ? (
                      <div className=' d-flex flex-column justify-content-center flex-grow-1'>
                        <button
                          type='button'
                          className='btn btn-success mx-auto w-75'
                          onClick={() => {
                            setCalibrationDevice(d);
                            setDevices(undefined);
                            changeStep("forward");
                          }}
                        >
                          Select
                        </button>
                        <button
                          type='button'
                          className='btn btn-outline-warning mx-auto w-75 mt-2'
                          onClick={() => {
                            setCalibrationDevice(d);
                            setDevices(undefined);
                            changeStep("calibrate");
                          }}
                        >
                          Calibrate
                        </button>
                      </div>
                    ) : (
                      <div className='col-4 d-flex flex-column justify-content-center'>
                        <button
                          type='button'
                          className='btn btn-warning mx-auto w-75'
                          onClick={() => {
                            setCalibrationDevice(d);
                            setDevices(undefined);
                            changeStep("calibrate");
                          }}
                        >
                          Calibrate
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
        }
        {
          // Check if no devices were found, state so if true
          devices &&
            devices.length === 0 &&
            !loading &&
            (errMsg === "No devices found" ? (
              <div className='text-center'>
                <i class='bi bi-x-octagon-fill fs-1 text-danger'></i>
                <h5>{errMsg}</h5>
                <h6>
                  Please check all device connections and make sure the device
                  is recognized on the Connector App.
                </h6>
              </div>
            ) : (
              <div className='text-center'>
                <i class='bi bi-x-octagon-fill fs-1 text-danger'></i>
                <h5>{errMsg}</h5>
              </div>
            ))
        }
      </div>
    </CardD>
  );
};

export default WizardDeviceSelection;
