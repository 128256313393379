import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecentSamples, useAdverse } from "../api";
import { useUserLocations } from "../api/user";
import { useParamsContext } from "../contexts";
import Footer from "../components/Footer";
import Navbar from "../components/SpectraNavbar";
import { CardA, CardC } from "../components/ui/Cards";
import { SequenceD } from "../components/ui/Sequence";
import { ColourCircleC } from "../components/ui/ColourCircle";
import { DateTime } from "luxon";
import { find } from "lodash";
import { CSVLink } from "react-csv";

export default function RecentSamples() {
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);

  const [dateRange, setDateRange] = useState({
    start: sevenDaysAgo,
    end: today,
  });
  const [selectedLocation, setSelectedLocation] = useState("All");
  const { data: adverse, isSuccess: adverseSuccess } = useAdverse();
  const { getColour, getTexture } = useParamsContext();
  const { data: locations } = useUserLocations();

  let headers = [
    { label: "Sample ID", key: "sampleId" },
    { label: "Location", key: "location" },
    { label: "Date", key: "processedDate" },
    { label: "Time", key: "time" },
    { label: "Presumed Substance", key: "presumedSubstance" },
    { label: "Colour", key: "colourName" },
    { label: "Texture", key: "textureName" },
    { label: "Bulk Scan", key: "bulkScanExport" },
    { label: "Trace Scan", key: "traceScanExport" },
    { label: "Adverse Reaction", key: "adverseReaction" },
    {
      label: "Adverse Reaction Intervention",
      key: "adverseReactionIntervention",
    },
    { label: "Notes", key: "notes" },
    { label: "Other Analytical Results", key: "otherResults" },
  ];

  const handleDateRangeChange = (event) => {
    const selectedDateRange = parseInt(event.target.value, 10);
    let endDate = new Date(); // Current date
    let startDate = new Date();
    startDate.setDate(endDate.getDate() - selectedDateRange);
    setDateRange({ start: startDate, end: endDate });
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const {
    data: recentSamples,
    isLoading,
    isSuccess,
    isError,
  } = useRecentSamples(dateRange, selectedLocation);

  const getAdverse = (categories, adverseList) => {
    const reactions = [];
    if (categories.length > 0) {
      for (let i = 0; i < categories.length; i++) {
        reactions.push(
          find(adverseList, ["id", categories[i].categoryId]).label
        );
      }
      return reactions;
    }
    return reactions;
  };

  let tableData =
    isLoading || isError
      ? []
      : recentSamples.sequences.map((sample) => {
          let newItem = {};

          //get colour
          let c = getColour(sample.colourId);
          newItem.colour = c;
          if (c?.id.includes("02") && c?.label !== "Light Grey") {
            newItem.colourName = `Light ${c?.label}`; //adding "light" in front of colour label for light colours
          } else {
            newItem.colourName = c?.label;
          }

          //get texture
          if (sample.texture) {
            let texture = getTexture(sample.texture.id);
            newItem.texture = texture;
            if (texture.label === "Other") {
              newItem.textureName = `${texture.label} - ${sample.texture.description}`;
            } else {
              newItem.textureName = texture.label;
            }
          } else {
            newItem.textureName = undefined;
            newItem.texture = undefined;
          }

          // newItem.processedDate = DateTime.fromSQL(
          //   //formatting date and time for export
          //   sample.processedDate.slice(0, sample.processedDate.indexOf("T")),
          //   { zone: "utc" } //convert to local time
          // )
          //   // .setZone("local")
          //   .toFormat("DDD");

          let localDate = DateTime.fromISO(sample.processedDate).setZone(
            "local"
          );

          newItem.processedDate = localDate.toFormat("DDD");

          newItem.time = localDate.toFormat("t");

          newItem.bulkScanFindings =
            sample.tests.filter(
              (item) =>
                item.rb_findings?.length > 0 &&
                item.scanType.toLowerCase() === "bulk-burst"
            ).length > 0
              ? sample.tests
                  .filter(
                    (item) =>
                      item.rb_findings.length > 0 &&
                      item.scanType.toLowerCase() === "bulk-burst"
                  )
                  .map(
                    (item) =>
                      item.rb_findings.map((finding) => finding.substance)
                    // .join(", ")
                  )
                  .flat()
              : sample.tests.filter(
                  (item) =>
                    item.findings?.length > 0 &&
                    item.scanType.toLowerCase() === "bulk-burst"
                ).length > 0
              ? sample.tests
                  .filter(
                    (item) =>
                      item.findings?.length > 0 &&
                      item.scanType.toLowerCase() === "bulk-burst"
                  )
                  .map(
                    (item) => item.findings.map((finding) => finding.substance)
                    // .join(", ")
                  )
                  .flat()
              : [];

          newItem.bulkScanExport = newItem.bulkScanFindings.join(", ");

          newItem.traceScanFindings =
            sample.tests.filter(
              (item) =>
                item.rb_findings?.length > 0 &&
                item.scanType.toLowerCase() === "trace-burst"
            ).length > 0
              ? sample.tests
                  .filter(
                    (item) =>
                      item.rb_findings.length > 0 &&
                      item.scanType.toLowerCase() === "trace-burst"
                  )
                  .map(
                    (item) =>
                      item.rb_findings.map((finding) => finding.substance)
                    // .join(", ")
                  )
                  .flat()
              : sample.tests.filter(
                  (item) =>
                    item.findings?.length > 0 &&
                    item.scanType.toLowerCase() === "trace-burst"
                ).length > 0
              ? sample.tests
                  .filter(
                    (item) =>
                      item.findings?.length > 0 &&
                      item.scanType.toLowerCase() === "trace-burst"
                  )
                  .map((item) =>
                    item.findings.map((finding) => finding.substance)
                  )
                  .flat()
              : [];

          newItem.traceScanExport = newItem.traceScanFindings.join(", ");

          newItem._id = sample._id;
          newItem.sampleId = sample.sampleId;
          newItem.location = locations.find(
            (location) => location.locationId === sample.locationId
          ).name;
          newItem.presumedSubstance =
            sample.presumedSubstance === "Other"
              ? `${sample.presumedSubstance} - ${sample.presumedSubDescription}`
              : sample.presumedSubstance;
          newItem.adverseReaction =
            sample.categories.length === 0 ? "No" : "Yes";
          newItem.adverseReactionIntervention = getAdverse(
            sample.categories,
            adverse
          );
          newItem.notes = sample.description;
          newItem.reachbackStatus = sample.reachbackComplete;
          newItem.otherResults =
            sample.otherResults && sample.otherResults.length > 0
              ? sample.otherResults
                  .map((result) => {
                    if (result.testType === "testStrip") {
                      return `${result.type} Test Strip: ${result.results}`;
                    } else if (result.type === "Other") {
                      return `${result.type} - ${result.otherTechniqueType}: ${result.results}`;
                    } else {
                      return `${result.type}: ${result.results}`;
                    }
                  })
                  .join(", ")
              : "N/A";
          return newItem;
        });

  useEffect(() => {
    if (locations && locations.length > 1) {
      setSelectedLocation("All");
    } else if (locations && locations.length === 1) {
      setSelectedLocation(locations[0].locationId);
    }
  }, [locations]);

  const calculateSelectedValue = () => {
    // Calculate the number of days in the date range
    //NOTE: When there is a time change in the date range, the calculation will be off by an hour,so we round it to the nearest whole number
    const daysDifference = Math.round(
      (dateRange.end - dateRange.start) / (1000 * 60 * 60 * 24)
    );

    // Find the corresponding option value
    switch (daysDifference) {
      case 7:
        return 7;
      case 14:
        return 14;
      case 30:
        return 30;
      case 60:
        return 60;
      case 90:
        return 90;
      default:
        return 0; // Default to 0 if it doesn't match any option
    }
  };

  if (isLoading) {
    return (
      <div className='flex-container'>
        <Navbar signedIn={false} />
        <div
          className='px-sm-3 px-lg-5 py-3 flex-body'
          style={{ marginTop: "79px" }}
        >
          <div
            className='alert  mx-3'
            role='alert'
            style={{ backgroundColor: "#7ECBF7", fontWeight: "bold" }}
          >
            Fetching samples ...
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (isError) {
    return (
      <div className='flex-container'>
        <Navbar signedIn={false} />
        <div
          className='px-sm-3 px-lg-5 py-3 flex-body'
          style={{ marginTop: "79px" }}
        >
          <div className='alert alert-danger mx-3' role='alert'>
            Something went wrong, try refreshing the page
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className='flex-container'>
      <Navbar signedIn={false} />
      <div
        className='px-sm-3 px-lg-5 py-3 flex-body'
        style={{ marginTop: "79px" }}
      >
        {isSuccess && (
          <CardA
            title='Recent Samples'
            subtitle='Click on a row to view and edit the sample.'
          >
            <div className='d-flex mb-3 justify-content-between '>
              <div className='d-flex'>
                <select
                  className='form-select w-auto'
                  id='dateRangeSelect'
                  value={calculateSelectedValue()}
                  onChange={handleDateRangeChange}
                >
                  <option value={7}>Past 7 Days</option>
                  <option value={14}>Past 14 Days</option>
                  <option value={30}>Past 30 Days</option>
                  <option value={60}>Past 60 Days</option>
                  <option value={90}>Past 90 Days</option>
                </select>

                {locations.length > 1 && (
                  <select
                    className='form-select w-auto'
                    id='locationSelect'
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <option key={"All"} value={"All"}>
                      All Locations
                    </option>
                    {locations.map((location) => (
                      <option
                        key={location.locationId}
                        value={location.locationId}
                      >
                        {location.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <CSVLink
                data={tableData}
                headers={headers}
                filename={`Samples ${dateRange.start
                  .toISOString()
                  .slice(0, 10)} to ${today.toISOString().slice(0, 10)}.csv`}
                className='btn btn-outline-primary'
              >
                Export to CSV
              </CSVLink>
            </div>
            {tableData.length === 0 ? (
              <div className='d-flex justify-content-center align-items-center flex-column flex-grow-1'>
                <i className='bi bi-search fs-1'></i>
                <h5>No samples found</h5>
                <h6>Please adjust the filter settings.</h6>
              </div>
            ) : (
              <div className='table-responsive flex-grow-1'>
                <table className='table table-hover'>
                  <thead>
                    <tr className='text-center'>
                      <th scope='col' className='p-1'>
                        Sample ID
                      </th>
                      <th scope='col' className='p-1'>
                        Date
                      </th>
                      <th scope='col' className='p-1'>
                        Time
                      </th>
                      <th scope='col' className='p-1'>
                        Presumed Substance
                      </th>
                      <th scope='col' className='p-1'>
                        Colour
                      </th>
                      <th scope='col' className='p-1'>
                        Texture
                      </th>
                      <th scope='col' className='p-1'>
                        Bulk Scan
                      </th>
                      <th scope='col' className='p-1'>
                        Trace Scan
                      </th>
                      <th scope='col' className='p-1'>
                        Reachback Status
                      </th>
                      <th scope='col' className='p-1'>
                        Adverse Reaction
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((sample) => (
                      <tr
                        key={sample._id}
                        onClick={() =>
                          (window.location = `/sample/${sample._id}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <td className='text-center align-middle'>
                          {sample.sampleId}
                        </td>
                        <td className='text-center align-middle'>
                          {sample.processedDate}
                        </td>
                        <td className='text-center align-middle'>
                          {sample.time}
                        </td>
                        <td className='text-center align-middle'>
                          {sample.presumedSubstance.includes("Fentalog")
                            ? sample.presumedSubstance.split(" ")[0]
                            : sample.presumedSubstance.includes(
                                "Benzodiazepines"
                              )
                            ? sample.presumedSubstance.split(" ")[0]
                            : sample.presumedSubstance}
                        </td>
                        <td className='text-center align-middle'>
                          {<ColourCircleC data={getColour(sample.colour.id)} />}
                        </td>
                        <td className='text-center align-middle'>
                          <CardC data={getTexture(sample.texture?.id)} />
                        </td>
                        <td className='align-middle'>
                          {
                            <SequenceD
                              findings={
                                sample.bulkScanFindings || ["Inconclusive"]
                              }
                            />
                          }
                        </td>

                        <td className='align-middle'>
                          {
                            <SequenceD
                              findings={
                                sample.traceScanFindings || ["Inconclusive"]
                              }
                            />
                          }
                        </td>

                        <td className='text-center align-middle'>
                          {sample.reachbackStatus === "N/A"
                            ? "n/a"
                            : sample.reachbackStatus === "inProgress"
                            ? "In Progress"
                            : "Complete"}
                        </td>

                        <td className='text-center align-middle'>
                          {sample.adverseReaction}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </CardA>
        )}
      </div>
      <Footer />
    </div>
  );
}
