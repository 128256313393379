import { Form, ToggleButtonGroup, ToggleButton } from "react-bootstrap";

const TestStripForm = ({ formData, handleChange, showValidationError }) => {
  return (
    <>
      <Form.Group>
        <Form.Label className='fs-6 fw-semibold'>Test Strip Type:</Form.Label>
        <div className='mb-3'>
          <Form.Select
            required
            aria-label='Test Strip Type Selection'
            placeholder='Select a type'
            defaultValue={formData.type}
            onChange={(e) => handleChange("type", e.target.value)}
          >
            <option hidden value=''>
              Select a type
            </option>
            <option value='Fentanyl'>Fentanyl</option>
            <option value='Benzodiazepine'>Benzodiazepine</option>
            <option value='Xylazine'>Xylazine</option>
          </Form.Select>
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label className='fs-6 fw-semibold'>Result:</Form.Label>
        <div className='mb-3'>
          <ToggleButtonGroup
            required
            type='radio'
            name='testStripResult'
            defaultValue={formData.results}
            onChange={(val) => {
              handleChange("results", val);
            }}
          >
            <ToggleButton
              name='testStripResult'
              variant='outline-secondary'
              id='positive'
              value={"Positive"}
            >
              Positive
            </ToggleButton>
            <ToggleButton
              name='testStripResult'
              variant='outline-secondary'
              id='negative'
              value={"Negative"}
            >
              Negative
            </ToggleButton>
            <ToggleButton
              name='testStripResult'
              variant='outline-secondary'
              id='inconclusive'
              value={"Inconclusive"}
            >
              Inconclusive
            </ToggleButton>
          </ToggleButtonGroup>
          {showValidationError && !formData.results && (
            <div className='invalid-feedback d-block'>
              Please select a test result.
            </div>
          )}
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label className='fs-6 fw-semibold'>Comments:</Form.Label>
        <Form.Control
          as='textarea'
          rows={3}
          defaultValue={formData.comments}
          onChange={(e) => {
            handleChange("comments", e.target.value);
          }}
        />
      </Form.Group>
    </>
  );
};

export default TestStripForm;
