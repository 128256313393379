import React from "react";
//import { BrowserRouter } from "react-router-dom";

import Body from "../components/Body";
import Footer from "../components/Footer";
import Navbar from "../components/SpectraNavbar";
import "../styles/styles.css"; // Import the CSS file

const Home = () => {
  return (
    <div className='flex-container'>
      <Navbar signedIn={false} />
      <div className='flex-body'>
        <Body />
      </div>
      <Footer />
    </div>
  );
};
export default Home;
