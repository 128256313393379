import React from "react";
// import palette from "../util/palette.js";

// Barchart component
import PieChart from "./charts/PieChart";

const TrendingDrugs = ({ commonDrugs }) => {
  const palette = [
    "#1CA3F0",
    "#F08021",
    "#E5E5E5",
    "#3E517A",
    "#333333",
    "#F39D53",
    "#747474",
    "#004AAD",
    "#8ED1F8",
    "#F1B11E",
  ];

  const getData = () => {
    let data = {
      datasets: [
        {
          data: getLocationData(),
          backgroundColor: palette.slice(0, commonDrugs.length),
        },
      ],
      labels: getLabels(),
    };
    return data;
  };

  const getLocationData = () => {
    const data = commonDrugs.map((drug) => drug.total);
    return data;
  };

  const getLabels = () => {
    const data = commonDrugs.map((drug) => drug._id);
    return data;
  };

  return (
    <>
      {commonDrugs.length == 0 ? (
        <div
          className='d-flex justify-content-center align-items-center h-100 bg-light'
          style={{ minWidth: "90%" }}
        >
          No data
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center h-100'>
          <PieChart data={getData()} />
        </div>
      )}
    </>
  );
};

export default TrendingDrugs;
