import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

// contexts
import { useSampleContext } from "../../../contexts";
import { useReachbackContext } from "../../../contexts/ReachbackContext";

import styleDate from "../../../util/dateStyler";

// components
import { CardA, CardC } from "../../ui/Cards";
import { ColourCircleB } from "../../ui/ColourCircle";
import { SequenceE, SequenceEB, SequenceF } from "../../ui/Sequence";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Line } from "react-chartjs-2";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const WasatchWizardSummary = ({
  step,
  steps,
  changeStep,
  pageID,
  bulk,
  trace,
}) => {
  const { wasatchSample, sample, flow, reset } = useSampleContext();
  const { submittedErrorsT, submittedErrorsB } = useReachbackContext();

  const [submit, setSubmit] = useState("");
  const [sampleID, setSampleID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const [loadingPercent, setLoadingPercent] = useState("0");
  const [startTime, setStartTime] = useState();
  const fakeLoading = useRef();
  const history = useHistory();

  async function handleSubmit() {
    const summary = {
      // customerId: sample.customerId,
      // locationId: sample.locationId,
      // deviceId: sample.deviceId,
      // description: sample.description,
      presumedSubstance: wasatchSample.presumed,
      presumedSubDescription: wasatchSample.presumedSubDescription,
      // tests: wasatchSample.trace,
      // notes: sample.notes,
      // categories: sample.categories,
      colour: wasatchSample.colour[1],
      texture: {
        id: wasatchSample.texture.id,
        description: wasatchSample.texture.description,
      },
      reachback: wasatchSample.reachback,
      // sampleId: sample.sampleId,
      // createdBy: sample.createdBy,
      backgroundScan: wasatchSample.background,
      traceScans: (wasatchSample.bulk || []).concat(wasatchSample.trace || []),
      flow: flow,
      reachback: submittedErrorsT || submittedErrorsB ? true : false,
      isErrorT: submittedErrorsT ? true : false,
      isErrorB: submittedErrorsB ? true : false,
      reachbackComplete:
        submittedErrorsT || submittedErrorsB ? "inProgress" : "N/A",
    };

    await axios
      .post("/companion/new-sample/submit-sample", summary)
      .then(function (res) {
        setSampleID(res.data);
        setSubmit("success");
      })
      .catch((error) => handleError(error));
    // submitSample(summary);
    // setSubmit(true);
  }

  function handleError(error) {
    console.log(error);
    setSubmit("error");
  }
  console.log(
    "Submitted Errors:",
    // submittedErrors,
    submittedErrorsT,
    submittedErrorsB
  );
  /**
   * Reset the summary page (remove the loading bar if necessary) when refreshing
   * the page.
   */
  useEffect(() => {
    if (step === pageID) {
      setLoadingText("");
      setLoadingPercent(0);
    }
  }, [step]);

  const handleReset = () => {
    reset();
  };

  /**
   * Fake function for the loading bar. Recursively calls itself to increase
   * loading bar percentage. Will load, with a degree of randomness. Increases
   * the percentage until just before 100%, usually in around 2-3sec.
   */
  function fakeLoadingBar(percent) {
    // Catch any calls outside the bounds (0 and 100 inclusive)
    if (percent < 0) {
      console.error("Negative percent given to loading bar.");
      updateLoadingBar(0);
      return;
    } else if (percent > 100) {
      console.error("Over 100 percent given to loading bar.");
      updateLoadingBar(100);
      return;
    }

    updateLoadingBar(percent);

    // Increase the percent by 15-34%, and wait between 0.4 & 0.8 sec
    let randPercent = percent + 15 + Math.floor(Math.random() * 20);

    // If the loading bar is about to finish, stop it (wait for server response)
    if (randPercent >= 100) {
      return;
    }
    fakeLoading.current = setTimeout(
      () => fakeLoadingBar(randPercent),
      400 + Math.floor(Math.random() * 400)
    );
  }

  /**
   * Stops the fake loading process.
   */
  function stopFakeLoadingBar() {
    setLoading(false);
    if (fakeLoading.current) {
      clearTimeout(fakeLoading.current);
    }
  }

  /**
   * Sets the loading message to fake details based on the percentage given.
   * @param {*} percent An int between 0 and 100 inclusive.
   */
  function updateLoadingBar(percent) {
    // console.log(`Setting percent to ${percent}`);
    if (percent < 0) {
      percent = 0;
      setLoadingText("Something went wrong. Please try again.");
    } else if (percent < 100) {
      setLoadingText("Sending sample results...");
    } else {
      percent = 100;
      setLoadingText(
        "Sample submitted! You will be redirected in 3 seconds..."
      );
    }
    setLoadingPercent(percent);
  }

  useEffect(() => {
    // If the sample submission failed, reset the loading bar
    if (submit === "error") {
      console.log(
        `Sample submission failed in ${
          (new Date().getTime() - startTime) / 1000
        } sec.`
      );
      stopFakeLoadingBar();
      updateLoadingBar(-1);
      setSubmit("");
    }

    // If the sumission was a success and the sampleID was set, prepare to redirect and finished the
    // loading bar
    if (submit === "success" && sampleID) {
      console.log(
        `Sample submission took ${
          (new Date().getTime() - startTime) / 1000
        } sec.`
      );
      stopFakeLoadingBar();
      updateLoadingBar(100);
      let timer = setTimeout(() => {
        setSubmit("");
        history.push(`/sample/${sampleID}`);
        handleReset();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [submit, sampleID, history]);

  if (step !== pageID) {
    return null;
  }

  return (
    <CardA
      title='Sample Summary'
      subtitle='Please confirm information before submitting for analysis.'
      steps={steps}
      step={step - 1}
    >
      <div className='my-2 d-flex flex-column justify-content-between flex-grow-1 gap-2'>
        <div className='card'>
          <div className=' d-flex justify-content-evenly flex-wrap p-2 '>
            <div className=''>
              <p className='mb-0'>
                <span className='fs-6 fw-semibold'>Presumed Substance: </span>
                {wasatchSample.presumed === "Other" &&
                wasatchSample.presumedSubDescription
                  ? `${wasatchSample.presumed} - ${wasatchSample.presumedSubDescription}`
                  : wasatchSample.presumed}
              </p>
            </div>
            <div className='d-flex'>
              <span className='fs-6 fw-semibold me-2'>Sample Colour: </span>
              <ColourCircleB
                colour={wasatchSample.colour[0]}
                label={wasatchSample.colour[2]}
              />
            </div>
            <div className='d-flex'>
              <span className='fs-6 fw-semibold me-2'>Sample Texture: </span>
              <CardC data={wasatchSample.texture} />
            </div>
          </div>
        </div>

        <div className='card d-flex flex-column flex-grow-1'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-12 col-xl-6'>
                <div className='d-flex align-items-center'>
                  <h6 className='mb-0 me-2 py-1' style={{ minHeight: "29px" }}>
                    Bulk Scan
                  </h6>
                  {submittedErrorsB ? (
                    <div className='d-flex align-items-center'>
                      <i
                        className='bi bi-exclamation-triangle-fill me-1'
                        style={{
                          fontSize: "large",
                          color: "red",
                        }}
                      ></i>
                      <span style={{ color: "red" }}>
                        Sample will be submitted for reachback service
                      </span>
                    </div>
                  ) : null}
                </div>

                {!wasatchSample.bulk || wasatchSample.bulk.length === 0 ? (
                  <p className='card-text'>Skipped</p>
                ) : (
                  <div className='row'>
                    {wasatchSample.bulk &&
                      wasatchSample.bulk.sort(
                        (seq1, seq2) => seq1.scanId - seq2.scanId
                      ) &&
                      wasatchSample.bulk.map((item, index) => {
                        return (
                          <div
                            className='col-12 mt-2 spectrum-card-container '
                            key={item.scanId}
                          >
                            <SequenceEB sequence={item} />
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
              <div className='col-12 col-xl-6 mt-3 mt-xl-0'>
                <div className='d-flex align-items-center '>
                  <h6 className='mb-0 me-2 py-1' style={{ minHeight: "29px" }}>
                    Trace Scan
                  </h6>
                  {submittedErrorsT ? (
                    <div className='d-flex align-items-center'>
                      <i
                        className='bi bi-exclamation-triangle-fill me-1'
                        style={{
                          fontSize: "large",
                          color: "red",
                        }}
                      ></i>
                      <span style={{ color: "red" }}>
                        Sample will be submitted for reachback service
                      </span>
                    </div>
                  ) : null}
                </div>
                {!wasatchSample.trace || wasatchSample.trace.length === 0 ? (
                  <p className='card-text'>Skipped</p>
                ) : (
                  <div className='row'>
                    {wasatchSample.trace &&
                      wasatchSample.trace.sort(
                        (seq1, seq2) => seq1.scanId - seq2.scanId
                      ) &&
                      wasatchSample.trace.map((item, index) => {
                        return (
                          <div
                            className='col-12 mt-2 spectrum-card-container '
                            key={item.scanId}
                          >
                            <SequenceEB sequence={item} />
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {(!wasatchSample.bulk || wasatchSample.bulk.length === 0) &&
          (!wasatchSample.trace || wasatchSample.trace.length === 0) && (
            <div className='row'>
              <div className='d-flex align-items-center'>
                <i
                  className='bi bi-exclamation-triangle-fill'
                  style={{
                    fontSize: "large",
                    color: "red",
                    marginRight: "10px",
                    marginBottom: "2px",
                  }}
                ></i>
                <span style={{ color: "red" }}>
                  Error: No scans selected. Please go back and select at least
                  one scan.
                </span>
              </div>
            </div>
          )}
        {/* <div className="card">
              <div className="card-body">
                <h5 className="card-title text-primary">Additional Information</h5>
                <h6>Tested</h6>
                <p className="card-text">
                  {(!wasatchSample.tested || wasatchSample.tested === "") ? "N/A" : `${wasatchSample.tested}`}
                </p>
              </div>
            </div> */}
      </div>
      {loadingText && (
        <div>
          <p className='text-center'>
            <strong>{loadingText}</strong>
          </p>
          <div className='progress w-75 mx-auto'>
            <div
              className='progress-bar progress-bar-striped progress-bar-animated'
              role='progressbar'
              style={{ width: loadingPercent + "%" }}
            ></div>
          </div>
        </div>
      )}

      <div className='row mt-2'>
        <button
          type='button'
          className='col-4 btn btn-secondary mx-auto w-30'
          disabled={submit || loading}
          onClick={() => changeStep("back")}
        >
          Back
        </button>
        <button
          type='button'
          className='col-4 btn btn-success mx-auto w-30'
          disabled={
            submit ||
            loading ||
            ((!wasatchSample.bulk || wasatchSample.bulk.length === 0) &&
              (!wasatchSample.trace || wasatchSample.trace.length === 0))
          }
          onClick={() => {
            setLoading(true);
            setStartTime(new Date().getTime());
            fakeLoadingBar(0);
            handleSubmit();
          }}
        >
          Submit for analysis
        </button>
      </div>
    </CardA>
  );
};

export default WasatchWizardSummary;
