import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import NavDropdown from "react-bootstrap/NavDropdown";

import { userActions } from "../../actions/auth";
import { history } from "../../helpers/history";

// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useUserLocations, useUserSettings } from "../../api/user";
//Elements and CSS from AntDesign
import "../../styles/Navbar.css";

import { Link } from "react-router-dom";

// import Login from './Login'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const DropdownMenu = ({ authenticated }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  const { data: locations } = useUserLocations();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const { data: settings } = useUserSettings();

  const queryClient = useQueryClient();

  const setLocation = useMutation(
    (location) =>
      axios.post("/user/post-location", {
        userId: settings.userId,
        locationId: location,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["settings"]);
        // This will refetch every query with "byLoc" at the start of the key
        queryClient.refetchQueries("byLoc");
      },
    }
  );

  useEffect(() => {
    // Check browser storage for authentication status
    const storedAuthStatus = localStorage.getItem("isAuthenticated");
    if (storedAuthStatus === "true") {
      // If authenticated, dispatch appropriate action
      dispatch(userActions.loginSuccess());
    }
    if (locations && settings) {
      setLocationList(locations);
      if (!selectedLocation) {
        setSelectedLocation(
          locations.filter((loc) => loc.locationId === settings.currentLoc)[0]
            .name
        );
      }
    }
  }, [dispatch, locations, settings]);

  const handleLogout = () => {
    dispatch(userActions.logout());
  };

  async function handleLocationChange(id) {
    setLocation.mutate(id, {
      onError: (error) => {
        console.log(error);
      },
    });
  }

  const onLocationSelect = (loc) => {
    setSelectedLocation(loc.name);
    handleLocationChange(loc.locationId);
  };

  return (
    <NavDropdown
      className='m-auto d-flex flex-column text-center'
      title={selectedLocation}
      id='dropdownMenuLoc'
    >
      {locationList && locationList.length > 1 && (
        <>
          <NavDropdown.Header>Locations</NavDropdown.Header>

          {locationList.map((loc, index) => (
            <NavDropdown.Item
              key={loc.locationId}
              onClick={(e) => {
                e.preventDefault();
                onLocationSelect(loc);
              }}
            >
              {loc.name}
            </NavDropdown.Item>
          ))}
          <NavDropdown.Divider />
        </>
      )}
      <NavDropdown.Item
        className='logout mx-auto'
        type='button'
        onClick={handleLogout}
      >
        Logout
        <FontAwesomeIcon
          className='dropdown-icon'
          id='logout-icon'
          icon={faArrowRightFromBracket}
        />
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default DropdownMenu;
