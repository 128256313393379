import { Form, ToggleButtonGroup, ToggleButton } from "react-bootstrap";

const OtherTechniquesForm = ({ formData, handleChange }) => {
  return (
    <>
      <Form.Group>
        <Form.Label className='fs-6 fw-semibold'>Technique Type:</Form.Label>
        <div className='mb-3'>
          <Form.Select
            required
            aria-label='Technique Type Selection'
            placeholder='Select a type'
            defaultValue={formData.type}
            onChange={(e) => handleChange("type", e.target.value)}
          >
            <option hidden value=''>
              Select a type
            </option>
            <option value='FTIR'>FTIR</option>
            <option value='Mass Spectrometry'>Mass Spectrometry</option>
            <option value='Other'>Other</option>
          </Form.Select>
        </div>
      </Form.Group>

      {formData.type === "Other" && (
        <Form.Group>
          <Form.Label className='fs-6 fw-semibold'>Other Technique:</Form.Label>
          <Form.Control
            required
            className='mb-3'
            type='text'
            placeholder='Please specify'
            defaultValue={formData.otherTechniqueType}
            onChange={(e) => handleChange("otherTechniqueType", e.target.value)}
          />
        </Form.Group>
      )}

      <Form.Group>
        <Form.Label className='fs-6 fw-semibold'>Results:</Form.Label>
        <Form.Control
          required
          className='mb-3'
          as='textarea'
          rows={3}
          defaultValue={formData.results}
          onChange={(e) => {
            handleChange("results", e.target.value);
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className='fs-6 fw-semibold'>Comments:</Form.Label>
        <Form.Control
          as='textarea'
          rows={3}
          defaultValue={formData.comments}
          onChange={(e) => {
            handleChange("comments", e.target.value);
          }}
        />
      </Form.Group>
    </>
  );
};

export default OtherTechniquesForm;
