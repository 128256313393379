// Barchart component

import BarChartAdverse from "./charts/BarChartAdverse";

const AdverseReactionPlot = ({ period, adverseData, noAdverseData }) => {
  const datasets = {
    7: {
      unit: "day",
    },
    14: {
      unit: "day",
    },
    30: {
      unit: "day",
    },
    60: {
      unit: "week",
    },
    90: {
      unit: "week",
    },
    180: {
      unit: "week",
    },
  };

  const getUnit = () => {
    return datasets[period].unit;
  };

  const getData = () => {
    let data = {
      datasets: [
        {
          label: "Adverse Reaction",
          data: adverseData ? adverseData.data : [],
          backgroundColor: "#1ca3f0",
          borderColor: "#a8e0ff",
        },
        {
          label: "No Adverse Reaction",
          data: noAdverseData ? noAdverseData.data : [],
          // backgroundColor: "#f08021",
          backgroundColor: "#3e517a",
          borderColor: "#a8e0ff",
        },
      ],
    };

    return data;
  };

  return (
    <>
      {!adverseData && !noAdverseData ? (
        <div className='d-flex justify-content-center align-items-center h-100 bg-light'>
          No data
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center h-100'>
          <BarChartAdverse data={getData()} unit={getUnit()} period={period} />
        </div>
      )}
    </>
  );
};

export default AdverseReactionPlot;
