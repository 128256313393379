import { Modal, Button, Form, ModalBody, ModalFooter } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useUserId } from "../../api/user";

const OrderFormModal = ({ showModal, handleClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailData, setEmailData] = useState({
    contactName: "",
    email: "",
    quantity: "",
    notes: "",
  });

  const {
    data: userId,
    isLoading: userIdIsLoading,
    isError: userIdIsError,
  } = useUserId();

  //this function stores the input value whenever there is a change
  const onInput = (e) => {
    setEmailData({ ...emailData, [e.target.id]: e.target.value });
  };

  //this function is called when the submit button is clicked, then calls the sendEmail function.
  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail();
  };

  // this function sends the email for reachback service
  const sendEmail = async () => {
    try {
      console.log("Sending email...");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subject: `Test Kit Order for User ID: ${userId}`,
            html: `<h1>Test Kit Order Information:</h1>
              <p>User ID: ${userId}</p>
              <p>Contact Name: ${emailData.contactName}</p>
              <p>Email: ${emailData.email}</p>
              <p>Quantity Requested: ${emailData.quantity}</p>
              <p>Notes: ${emailData.notes}</p>`,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log(data.messageId);
        console.log("Email sent successfully!");
        setIsSubmitted(true);
      } else {
        console.error("Error sending email:", data.error);
        console.error("Email sending failed!");
        setIsEmailError(true);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      console.error("Email sending failed!");
      setIsEmailError(true);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} backdrop='static' size='lg'>
      {!isSubmitted && !isEmailError && (
        <span>
          <Modal.Header closeButton>
            <Modal.Title>Amplifi ID Test Kits Order Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form id='formElement' onSubmit={(e) => handleSubmit(e)}>
              <Form.Group className='mb-3' controlId='contactName'>
                <Form.Label>Contact Name:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  onChange={onInput}
                  required
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='email'>
                <Form.Label>Email Address:</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='name@example.com'
                  onChange={onInput}
                  required
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='quantity'>
                <Form.Label>Quantity:</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Select a value'
                  required
                  onChange={onInput}
                >
                  <option hidden value=''>
                    Select a value
                  </option>
                  {/* <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option> */}
                  <option value='25'>25</option>
                  <option value='30'>30</option>
                  <option value='35'>35</option>
                  <option value='40'>40</option>
                  <option value='45'>45</option>
                  <option value='50'>50</option>
                  <option value='55'>55</option>
                  <option value='60'>60</option>
                  <option value='65'>65</option>
                  <option value='70'>70</option>
                  <option value='75'>75</option>
                  <option value='80'>80</option>
                  <option value='85'>85</option>
                  <option value='90'>90</option>
                  <option value='95'>95</option>
                  <option value='100'>100</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className='mb-3' controlId='notes'>
                <Form.Label>Notes:</Form.Label>
                <Form.Control as='textarea' rows={3} onChange={onInput} />
              </Form.Group>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' type='submit'>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </span>
      )}

      {isSubmitted && !isEmailError && (
        <span>
          <ModalBody>
            <h1>Your order was successfully submitted!</h1>
            <p>
              If you have any questions, please contact{" "}
              <a href='mailto:info@spectraplasmonics.com'>
                info@spectraplasmonics.com
              </a>
            </p>
          </ModalBody>
          <ModalFooter>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </span>
      )}

      {isEmailError && (
        <span>
          <ModalBody>
            <h1>Your request couldn't be submitted.</h1>
            <p>
              Please try again or contact{" "}
              <a href='mailto:info@spectraplasmonics.com'>
                info@spectraplasmonics.com
              </a>
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='secondary'
              onClick={() => {
                handleClose();
                setIsEmailError(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </span>
      )}
    </Modal>
  );
};

export default OrderFormModal;
