import React, { useEffect, useState } from "react";

//contexts
import { useSampleContext } from "../../contexts";
import { useNewColours } from "./../../api";
import { useTextures } from "./../../api";

//components
import { CardA, CardB } from "../ui/Cards";
import { Alert } from "antd";
import "antd/dist/antd.css";

import { ColourSquare } from "./../ui/ColourCircle";

import SampleCons from "../../constants/sample";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const WizardSampleInfo = ({ step, steps, changeStep, pageID }) => {
  const {
    sample,
    wasatchSample,
    setPresumedSubstance,
    flow,
    setColour,
    setTexture,
  } = useSampleContext();

  const [warning, setWarning] = React.useState(<></>);
  const [selectedPresumed, setSelectedPresumed] = useState(
    flow === "wasatch" ? wasatchSample.presumed : sample.presumed
  );
  const [selectedColour, setSelectedColour] = useState(
    flow === "wasatch" ? wasatchSample.colour[1] : sample.colour[1]
  );
  const [selectedTexture, setSelectedTexture] = useState(
    flow === "wasatch" ? wasatchSample.texture.id : sample.texture.id
  );

  const [isDisabled, setIsDisabled] = useState(true);

  const {
    data: coloursData,
    isFetching: isFetchingColours,
    isError: isErrorColours,
  } = useNewColours();

  const {
    data: texturesData,
    isFetching: isFetchingTextures,
    isError: isErrorTextures,
  } = useTextures();

  //checks if the required fields are filled in
  const validateFields = () => {
    if (
      !(flow === "wasatch" ? wasatchSample?.presumed : sample?.presumed) ||
      !(flow === "wasatch" ? wasatchSample?.colour[1] : sample?.colour[1]) ||
      !(flow === "wasatch" ? wasatchSample?.texture.id : sample?.texture.id) ||
      (flow === "wasatch"
        ? wasatchSample?.texture.id === "OTH" &&
          !wasatchSample?.texture.description
        : sample?.texture.id === "OTH" && !sample?.texture.description) ||
      (flow === "wasatch"
        ? wasatchSample?.presumed === "Other" &&
          !wasatchSample?.presumedSubDescription
        : sample?.presumed === "Other" && !sample?.presumedSubDescription)
    ) {
      console.log("disabled");
      setIsDisabled(true);
    } else {
      console.log("enabled");
      setIsDisabled(false);
    }
  };

  //triggers the validateFields function whenever the selected values change
  useEffect(() => {
    validateFields();
  }, [
    selectedPresumed,
    selectedColour,
    selectedTexture,
    sample.texture.description,
    wasatchSample.texture.description,
    sample.presumedSubDescription,
    wasatchSample.presumedSubDescription,
  ]);

  const handleSubstanceChange = (newSubstance, description) => {
    setPresumedSubstance(newSubstance, description);
    setSelectedPresumed(newSubstance);

    if (flow !== "wasatch") {
      if (SampleCons.PRESUMED_SUBSTANCES.indexOf(newSubstance) < 0) {
        setWarning(<></>);
      } else if (SampleCons.TRACE_SUPPORTED.indexOf(newSubstance) < 0) {
        setWarning(
          <Alert
            message='Warning'
            description='This substance class is currently not supported for trace scans.'
            type='warning'
            showIcon
          />
        );
      } else {
        setWarning(<></>);
      }
    }
  };

  // if colours cannot be fetched
  if (!isFetchingColours && isErrorColours) {
    return <h1>Cannot fetch colours</h1>;
  }
  if (!isFetchingTextures && isErrorTextures) {
    return <h1>Cannot fetch textures</h1>;
  }

  const changeColour = (colour, id, text) => {
    setSelectedColour(id);
    setColour([colour, id, text]);
  };

  const allColours = () => {
    return coloursData?.map((item) => {
      if (!item.label) {
        return (
          <div className='d-flex flex-row w-100 justify-content-center mt-2 flex-wrap'>
            {item.colours.map((colour) => {
              const colourName = colour.label;
              const hexCode = colour.hexCode;
              const id = colour.id;
              return (
                <ColourSquare
                  key={id}
                  id={id}
                  colour={hexCode}
                  selected={selectedColour === id}
                  updateColour={changeColour}
                  text={colourName}
                  showLabel={true}
                />
              );
            })}
          </div>
        );
      } else {
        const colourName = item.label;
        return (
          <div className='d-flex flex-column mx-2 mb-2 text-center '>
            {colourName}
            <div className='d-flex flex-row w-100 justify-content-center '>
              {item.colours.map((colour) => {
                const hexCode = colour.hexCode;
                const id = colour.id;
                return (
                  <ColourSquare
                    key={id}
                    id={id}
                    colour={hexCode}
                    selected={selectedColour === id}
                    updateColour={changeColour}
                    text={colourName}
                  />
                );
              })}
            </div>
          </div>
          // </div>
        );
      }
    });
  };

  const changeTexture = (texture, id, img, description) => {
    setSelectedTexture(id);
    setTexture({ label: texture, id: id, img: img, description: description });
  };

  const allTextures = () => {
    return texturesData?.map((item) => {
      return (
        <CardB
          key={item.id}
          id={item.id}
          title={item.label}
          img={item.img}
          description={
            flow === "wasatch"
              ? wasatchSample.texture.description
              : sample.texture.description
          }
          selected={selectedTexture === item.id}
          updateTexture={changeTexture}
        />
      );
    });
  };

  if (step !== pageID) {
    return null;
  }
  return (
    <CardA
      title='Sample Information'
      subtitle='Choose the presumed substance, colour, and texture from the list below. This will not impact your final results.'
      // subtitle2='* = required'
      steps={steps}
      step={step - 1}
      // styleConfig={{
      //   size: "1.3em",
      //   activeBgColor: "#007bff",
      //   completedBgColor: "#0061c9",
      //   shape: "circle",
      //   color: "#fff",
      // }}
    >
      <div className='d-flex flex-column h-100 flex-grow-1 pt-3'>
        <div className='d-flex flex-column flex-md-row flex-grow-1'>
          <div className='col-12 col-md-4 px-md-3 py-3 py-md-0'>
            <h6>
              Presumed Substance{" "}
              <small className='fst-italic'>(required)</small>
            </h6>
            {/* <h6 className='card-subtitle mb-4 text-muted'>
            Choose the presumed substance from the list below
          </h6> */}
            {/* <select
            className='form-control mb-2'
            value={
              flow === "wasatch" ? wasatchSample.presumed : sample.presumed
            }
            onChange={(e) => handleSubstanceChange(e.target.value)}
          >
            <option value=''>Select presumed substance</option>
            {SampleCons.PRESUMED_SUBSTANCES.map((substance) => {
              return <option value={substance}>{substance}</option>;
            })}
          </select> */}
            <div className='row justify-content-center'>
              <div
                className='accordion w-100 px-2 px-xl-5 '
                id='accordionExample'
              >
                {SampleCons.NEW_PRESUMED_SUBSTANCES.map((substance) => {
                  let indexOfSpace = substance.label.indexOf(" ");
                  let newLabel = substance.label.slice(0, indexOfSpace);

                  let isOpen = substance.drugs.includes(selectedPresumed);

                  return (
                    <div className='accordion-item' key={substance.label}>
                      <h2 className='accordion-header' id={substance.label}>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#${newLabel}-collapse`}
                          aria-expanded={String(isOpen)}
                          aria-controls={`${newLabel}-collapse`}
                        >
                          {substance.label}
                        </button>
                      </h2>

                      <div
                        id={`${newLabel}-collapse`}
                        className={
                          isOpen
                            ? "accordion-collapse collapse show"
                            : "accordion-collapse collapse"
                        }
                        aria-labelledby={newLabel}
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          {substance.drugs.map((drug) => {
                            let isChecked = false;
                            if (selectedPresumed === drug) {
                              isChecked = true;
                            }
                            return (
                              <>
                                <div className='form-check'>
                                  <input
                                    key={drug}
                                    type='radio'
                                    className='form-check-input'
                                    name='drugs-radio'
                                    id={drug}
                                    checked={isChecked}
                                    onChange={() =>
                                      handleSubstanceChange(drug, "")
                                    }
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={drug}
                                  >
                                    {drug}
                                  </label>
                                </div>
                                {drug === "Other" && isChecked && (
                                  <div className='form-group mt-2'>
                                    <input
                                      type='text'
                                      className='form-control'
                                      id='otherSubstance'
                                      placeholder='Please specify (required)'
                                      style={{ fontSize: "12px" }}
                                      aria-label='Other'
                                      maxLength={"30"}
                                      onChange={(e) =>
                                        handleSubstanceChange(
                                          "Other",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {/* value={flow === "wasatch" ? wasatchSample?.presumed : sample?.presumed} } */}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {warning}
          </div>
          <div className='d-none d-md-block border-end'></div>
          <div className='d-md-none d-block border-bottom'></div>
          <div className='col-12 col-md-4 px-md-3 py-3 py-md-0'>
            <h6>
              Sample Colour <small className='fst-italic'>(required)</small>
            </h6>

            <div className='d-flex flex-wrap mx-auto justify-content-center'>
              {allColours()}
            </div>
          </div>

          <div className='d-none d-md-block border-end'></div>
          <div className='d-md-none d-block border-bottom'></div>
          <div className='col-12 col-md-4 px-md-3 py-3 py-md-0'>
            <h6>
              Sample Texture <small className='fst-italic'>(required)</small>
            </h6>

            <div className='d-flex flex-wrap justify-content-center'>
              {allTextures()}
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4 align-content-center'>
        <span className='col-6 d-flex justify-content-center'>
          <button
            type='button'
            className='btn btn-secondary mx-auto w-75'
            onClick={() => changeStep("back")}
          >
            Back
          </button>
        </span>

        <OverlayTrigger
          overlay={
            isDisabled ? (
              <Tooltip id='tooltip-disabled'>
                Please complete all required fields
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className='col-6 d-flex justify-content-center'>
            <button
              type='button'
              className='btn btn-primary mx-auto w-75 '
              onClick={() => changeStep("forward")}
              disabled={isDisabled}
            >
              Continue
            </button>
          </span>
        </OverlayTrigger>
      </div>
    </CardA>
  );
};

export default WizardSampleInfo;
