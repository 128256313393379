import "../../styles/Navbar.css";
import NavDropdown from "react-bootstrap/NavDropdown";

const MoreDropdown = ({ setModalVisible }) => {
  return (
    <NavDropdown
      title='More'
      id='dropdownMenuMore'
      className='text-center mb-2 mb-lg-0'
    >
      <NavDropdown.Item
        href='https://www.spectraplasmonics.com/amplifi-id-faq'
        target='_blank'
      >
        FAQ
      </NavDropdown.Item>

      <NavDropdown.Item
        href='https://spectrapi.sharepoint.com/:f:/s/AmplifiIDCustomerPortal/EtYVgK7q_KpMrWELRdYwvq8B3Hae-MZg5Q0D6oZC9b4StA'
        target='_blank'
      >
        Customer Portal
      </NavDropdown.Item>

      <NavDropdown.Item
        as='button'
        // href='#'
        onClick={(event) => {
          event.preventDefault();
          setModalVisible(true);
        }}
      >
        Order Test Kits
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default MoreDropdown;
