import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "core-js";
// import "./index.css";

// redux for advanced state management
import { Provider } from "react-redux";
import { store } from "./helpers/Store";

// provider for api calls
import { QueryClient, QueryClientProvider } from "react-query";

// provider for local contexts
import { SampleProvider, ParamsProvider } from "./contexts";
import { ReachbackProvider } from "./contexts/ReachbackContext";

// Bootstrap CSS and JS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Routes
import Routes from "./Routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

ReactDOM.render(
  <ReachbackProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ParamsProvider>
          <SampleProvider>
            <Routes />
          </SampleProvider>
        </ParamsProvider>
      </QueryClientProvider>
    </Provider>
  </ReachbackProvider>,
  document.getElementById("root")
);
