import axios from "axios";
import { se } from "date-fns/locale";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

async function editReaction({ id, adverse }) {
  console.log("adverse:", [...new Set(adverse)]);
  try {
    const res = await axios.post("/companion/view-edit-sample/edit-sample", {
      id: id,
      adverseReactions: [...new Set(adverse)],
    });
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

async function editReachback({ id, reachback }) {
  try {
    const res = await axios.post("/companion/view-edit-sample/edit-reachback", {
      id: id,
      reachback: reachback,
    });
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

async function editFlag({ id, flag }) {
  try {
    const res = await axios.post(
      "/companion/view-edit-sample/edit-sample-test",
      {
        id: id,
        tested: flag,
      }
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

async function editDescription({ id, description }) {
  try {
    const res = await axios.post(
      "/companion/view-edit-sample/edit-sample-description",
      {
        id: id,
        description: description,
      }
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}
async function editIsReachbackEmailSent({ id, isReachbackEmailSent }) {
  try {
    const res = await axios.post(
      "/companion/view-edit-sample/edit-sample-isReachbackEmailSent",
      {
        id: id,
        isReachbackEmailSent: isReachbackEmailSent,
      }
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}
async function editReachbackComplete({ id, reachbackComplete }) {
  try {
    const res = await axios.post(
      "/companion/view-edit-sample/edit-sample-reachbackComplete",
      {
        id: id,
        reachbackComplete: reachbackComplete,
      }
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

async function editOtherResults({ id, otherResults }) {
  console.log("id:", id, "otherResults:", otherResults);
  try {
    const res = await axios.post(
      "/companion/view-edit-sample/edit-sample-otherResults",
      {
        id: id,
        otherResults: otherResults,
      }
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

async function editOtherResultsDelete({ id, otherResultsDelete }) {
  try {
    const res = await axios.post(
      "/companion/view-edit-sample/edit-sample-otherResults-delete",
      {
        id: id,
        otherResultsDelete: otherResultsDelete,
      }
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function edit({ id, field, value }) {
  try {
    switch (field) {
      case "adverse":
        await editReaction({ id, adverse: value });
        break;
      case "flag":
        await editFlag({ id, flag: value });
        break;
      case "description":
        await editDescription({ id, description: value });
        break;
      case "reachback":
        await editReachback({ id, reachback: value });
        break;
      case "isReachbackEmailSent":
        await editIsReachbackEmailSent({ id, isReachbackEmailSent: value });
        break;
      case "reachbackComplete":
        await editReachbackComplete({ id, reachbackComplete: value });
        break;
      case "otherResults":
        await editOtherResults({ id, otherResults: value });
        break;
      case "otherResultsDelete":
        await editOtherResultsDelete({ id, otherResultsDelete: value });
        break;
      default:
        console.log("No field selected");
    }
  } catch (error) {
    console.error("Error: ", error);
  }
}

export async function commonDrugs(ldate, drug, selectedLocation) {
  const { data } = await axios.post("/companion/body-stats/commonDrugs", {
    ldate,
    drug,
    selectedLocation,
  });
  return data;
}

export async function adverseReactions(ldate, substance) {
  const { data } = await axios.post("/companion/body-stats/adverseReactions", {
    ldate,
    substance,
  });
  return data;
}
