import { Modal, Button, ModalBody, ModalFooter } from "react-bootstrap";

const DeleteResultModal = ({ show, handleClose, handleDelete }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Result</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <p>Are you sure you want to delete this result?</p>
      </ModalBody>
      <ModalFooter>
        <Button variant='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='danger' onClick={handleDelete}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteResultModal;
