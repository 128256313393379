import { useEffect, useRef } from "react";
import { getFilteredFindings } from "../../helpers/samples";

// import { Collapse } from "antd";
import { Line } from "react-chartjs-2";
// Bootstrap
import "bootstrap-icons/font/bootstrap-icons.css";
import styleDate from "../../util/dateStyler";

import { BiCheckCircle } from "react-icons/bi";

import SampleCons from "../../constants/sample";

function getSubstanceString(findings) {
  return findings
    .map(
      (finding) =>
        finding.substance.charAt(0).toUpperCase() + finding.substance.slice(1) // +
      // (finding.likelihood === undefined ? "" : ` (${finding.likelihood})`) // This prints high and low confidence for each analyte
    )
    .join(", ");
}

const getAnalyteName = (analyte) => {
  return (
    SampleCons.ANALYTE_NAMES.find((item) => item.modelName === analyte)
      ?.displayName || addSpaces(analyte)
  );
};

//add spaces between camel case
const addSpaces = (string) => {
  return string.replace(/(?!\[)([^A-Z])([A-Z])(?![A-Z])/g, "$1 $2").trim();
};

export function Sequence({ sequence, checked, add, remove }) {
  // const [selected, setSelected] = useState(checked)

  const handleSelect = () => {
    if (!checked) {
      add(sequence);
    } else {
      remove(sequence.sequenceId);
    }
  };

  return (
    <label
      className={`card my-3 w-50 shadow-sm ${
        checked ? "border-2 border-primary" : ""
      }`}
      onClick={() => handleSelect()}
    >
      <div className='card-body row'>
        <div className='col-auto me-auto'></div>
        <div className='col'>
          <span className='row'>Date: {styleDate(sequence.date)}</span>
          <span className='row'>Sequence ID: {sequence.sequenceId}</span>
          <span className='row'>
            {"Findings: "}
            {sequence.findings.length > 0 &&
            typeof sequence.findings[0] === "string"
              ? sequence.findings.map((item) => item + " ")
              : getSubstanceString(getFilteredFindings(sequence.findings))}
          </span>
        </div>
      </div>
    </label>
  );
}

export function SequenceB({ findings, sequenceId, pureSubstance }) {
  return (
    <div className='border border-3 rounded bg-light fs-6 fw-semibold w-75 me-0 me-sm-5 p-2'>
      <ul className='mb-0'>
        {Array.isArray(findings) ? (
          findings.length > 0 && typeof findings[0] === "string" ? (
            findings.map((finding, index) =>
              pureSubstance && pureSubstance[index] === "Yes" ? (
                <li key={index} className=''>
                  {getAnalyteName(finding)}{" "}
                  <i className=' text-success'>*High purity</i>
                </li>
              ) : (
                <li key={index}>{getAnalyteName(finding)}</li>
              )
            )
          ) : (
            getFilteredFindings(findings).map((finding, index) => (
              <li key={index}>{getSubstanceString([finding])}</li>
            ))
          )
        ) : (
          <li>No findings available</li>
        )}
      </ul>
      {/* <small className="font-weight-bold">Sequence {sequenceId}</small> */}
    </div>
  );
}

export function SequenceC({ adverse, checked, add, remove, prevSelect }) {
  const handleSelect = () => {
    if (!checked && !prevSelect) {
      add(adverse);
    }
    if (checked || prevSelect) {
      remove(adverse.id);
    }
  };

  return (
    <div
      className={`btn btn-light m-2 p-3 shadow-sm ${
        checked ? "border-2 border-primary" : ""
      } ${prevSelect ? "border-2 border-success" : ""}`}
      onClick={() => handleSelect()}
    >
      <span className=''>{adverse.label}</span>
    </div>
  );
}

export function SequenceD({ findings }) {
  return (
    <div className='border bg-light p-1'>
      {findings.length > 0 && typeof findings[0] === "string" ? (
        <ul className='font-weight-bold align-middle mb-0 ps-4'>
          {findings.map((finding, index) => (
            <p className='mb-0 font-weight-bold' key={index}>
              <li>{getAnalyteName(finding)} </li>
            </p>
          ))}
        </ul>
      ) : (
        <p className='text-center mb-0'>No scan</p>
      )}
    </div>
  );
}

export function SequenceE({ sequence, valid, onSelect, isSelected }) {
  // const errorFlag = sequence.subScans[0]?.errorCheck?.errorFlag || 0;

  // Determine if it's a good scan quality
  let isGoodScanQuality = true;
  sequence.errorCheck?.errorFlag === 1
    ? (isGoodScanQuality = false)
    : (isGoodScanQuality = true);

  console.log("SequenceE - sequence:", sequence);
  const handleSelect = () => {
    onSelect(sequence);
  };

  // Round the wavenumber_plot values to the nearest 100s
  const roundedWavenumbers = sequence.wavenumber_plot.map(
    (value) => Math.round(value / 100) * 100
  );

  const spectraChartData = {
    labels: roundedWavenumbers,
    datasets: [
      {
        label: "Spectra Scan",
        data: sequence.processed_data_plot,
        fill: false,

        borderColor: "#0093EA",
        tension: 0.1,
      },
    ],
  };

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    scales: {
      x: {
        title: {
          display: true,
          text: "Raman shift (cm^-1)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Intensity (a.u.)",
        },
      },
    },
    elements: {
      point: {
        radius: 1,
      },
    },
    plugins: {
      legend: {
        align: "end",
        display: false,
        labels: {
          boxWidth: 10,
        },
      },
    },
  };

  return (
    <label
      className={`shadow card h-100 d-flex flex-grow-1 border-3`}
      style={{
        backgroundColor: isSelected ? "#F4FAFE" : "",
        borderColor: isSelected ? "#0E8FDA" : "",
      }}
      onClick={handleSelect}
    >
      <div className=' card-body h-100 d-flex flex-column flex-grow-1 justify-content-between'>
        <div className=' d-flex flex-column align-items-center h-100'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div>
              <strong>Scan Number:&nbsp; </strong> {sequence.scanId}
            </div>
            <div>
              <strong>Date:&nbsp; </strong> {styleDate(sequence.date)}
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-center flex-grow-1 w-100'>
            <Line data={spectraChartData} options={graphOptions} />
          </div>
        </div>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          {isGoodScanQuality && (
            <div className='d-flex mb-2'>
              <BiCheckCircle // Green checkmark icon
                style={{
                  fontSize: "x-large",
                  color: "green",
                  marginRight: "10px",
                }}
              />
              <strong>Good scan! Submit for analysis</strong>
            </div>
          )}

          {!isGoodScanQuality && (
            <div className='row'>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // marginTop: "30px",
                }}
              >
                <div
                  className='bi-exclamation-square-fill'
                  style={{
                    fontSize: "large",
                    color: "red",
                    marginRight: "10px",
                    marginBottom: "2px",
                  }}
                ></div>
                {sequence.errorCheck?.signalQuality ===
                "out of training distrubution" ? (
                  <strong>
                    Complex spectrum. Reachback support recommended
                  </strong>
                ) : (
                  <strong>{sequence.errorCheck?.signalQuality}</strong>
                )}
              </div>
            </div>
          )}

          {/* {sequence.subScans[0]?.errorCheck?.errorFlag === 0 && ( */}
        </div>
      </div>
    </label>
  );
}

export function SequenceEB({ sequence }) {
  // Round the wavenumber_plot values to the nearest 100s
  const roundedWavenumbers = sequence.wavenumber_plot.map(
    (value) => Math.round(value / 100) * 100
  );

  const spectraChartData = {
    labels: roundedWavenumbers,
    datasets: [
      {
        label: "Spectra Scan",
        data: sequence.processed_data_plot,
        fill: false,

        borderColor: "#0093EA",
        tension: 0.1,
      },
    ],
  };

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    scales: {
      x: {
        title: {
          display: true,
          text: "Raman shift (cm^-1)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Intenisty (a.u.)",
        },
      },
    },
    elements: {
      point: {
        radius: 1,
      },
    },
    plugins: {
      legend: {
        align: "end",
        display: false,
        labels: {
          boxWidth: 10,
        },
      },
    },
  };

  return (
    <label className={`card shadow-sm border-3`}>
      <div className='card-body '>
        <div className='d-flex justify-content-between'>
          <div>
            <strong>Scan Number: </strong> {sequence.scanId}
          </div>
          <div>
            <strong>Date: </strong> {styleDate(sequence.date)}
          </div>
        </div>
        <div style={{ maxWidth: "100%" }}>
          <Line data={spectraChartData} options={graphOptions} />
        </div>
      </div>
    </label>
  );
}

export function SequenceF({ deviceID, sequenceId, date }) {
  return (
    <div className='shadow-sm p-2'>
      <div>
        {sequenceId ? (
          <div className='d-flex flex-row'>
            {/* <div className='col text-muted'>{deviceID}</div> */}
            <div className='col text-muted'>Scan #{sequenceId}</div>
            <small className='font-weight-bold'>{date}</small>
          </div>
        ) : (
          <div>
            {/* <div className='text-muted'>{deviceID}</div> */}
            <small className='font-weight-bold'>{date}</small>
          </div>
        )}
      </div>
    </div>
  );
}

export function SequenceRadio({ flag, checked, select }) {
  return (
    <div>
      <input
        className='form-check-input'
        type='radio'
        name={flag}
        id={flag}
        checked={checked}
        onChange={() => select(flag)}
      />
      <label className='form-check-label' htmlFor={flag}>
        {flag === "" ? "N/A" : `${flag}`}
      </label>
    </div>
  );
}
export function SequenceRadioB({ name, value, id, checked, onChange }) {
  return (
    <div>
      <input
        className='form-check-input'
        type='radio'
        name={name}
        value={value}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label className='form-check-label' htmlFor={id}>
        {value === "" ? "N/A" : `${value}`}
      </label>
    </div>
  );
}
